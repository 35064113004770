import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';
import { BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";
import './index.css';
import App from './components/App'
import 'bootstrap/dist/css/bootstrap.min.css';

const anyWindow = window;

anyWindow.renderApp = () => {
    if ((window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") && window.location.port === "3000") {
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <BrowserRouter><App /></BrowserRouter>
        );
    } else {
        ReactDOM.hydrateRoot(document.getElementById('root'), <BrowserRouter><App /></BrowserRouter>)
    }
}
anyWindow.renderAppOnServer = () => {
    return ReactDOMServer.renderToString(<StaticRouter location={anyWindow.requestUrl} ><App /></StaticRouter>)
}
anyWindow.isServer = false
