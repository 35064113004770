export const sections_data = {
    "dorty": {
        title: "Dorty",
        categories: {
            "Dětské": {
                image: "images/dort-detsky-menu-tiny.png",
                items: [
                    { name: "Peppa Pig ", secondary: "", image: "images/dorty/detske/dort_pepa_pig.jpg" },
                    { name: "Tlapková Patrola ", secondary: "", image: "images/dorty/detske/dort_tlapkova_patrola.jpg" },
                    { name: "Alzák ", secondary: "", image: "images/dorty/detske/dort_alzak.jpg" },
                    { name: "Autíčko ", secondary: "", image: "images/dorty/detske/dort_auticko.jpg" },
                    { name: "Čarodějky ", secondary: "", image: "images/dorty/detske/dort_carodejky.jpg" },
                    { name: "Dort dvoupatrový Minnie ", secondary: "", image: "images/dorty/detske/dort_dvoupatrovy_minnie.jpg" },
                    { name: "Farma 1 ", secondary: "", image: "images/dorty/detske/dort_farma_1.jpg" },
                    { name: "Farma 2 ", secondary: "", image: "images/dorty/detske/dort_farma_2.jpg" },
                    { name: "Hello Kitty ", secondary: "", image: "images/dorty/detske/dort_hello_kity.jpg" },
                    { name: "Kočka ", secondary: "", image: "images/dorty/detske/dort_kocka.jpg" },
                    { name: "Korunka ", secondary: "", image: "images/dorty/detske/dort_korunka.jpg" },
                    { name: "Medvídek ", secondary: "", image: "images/dorty/detske/dort_medvidek.jpg" },
                    { name: "Minecraft 1 ", secondary: "", image: "images/dorty/detske/dort_minecraft_1.jpg" },
                    { name: "Minecraft 2 ", secondary: "", image: "images/dorty/detske/dort_minecraft_2.jpg" },
                    { name: "Mišánek ", secondary: "", image: "images/dorty/detske/dort_misanek_kremovy.jpg" },
                    { name: "Mořský svět ", secondary: "", image: "images/dorty/detske/dort_morsky_svet.jpg" },
                    { name: "Nepořádek ", secondary: "", image: "images/dorty/detske/dort_neporadek.jpg" },
                    { name: "Nike ", secondary: "", image: "images/dorty/detske/dort_nike.jpg" },
                    { name: "Bob a Bobek ", secondary: "", image: "images/dorty/detske/bob_a_bobek_1.jpg" },
                    { name: "Jednorožec ", secondary: "", image: "images/dorty/detske/unicorn_1.jpg" },
                    { name: "Krteček ", secondary: "", image: "images/dorty/detske/krtecek_1.jpg" },
                    { name: "Máša a medvěd ", secondary: "", image: "images/dorty/detske/masa_a_medved_1.jpg" },
                    { name: "Medvěd ", secondary: "", image: "images/dorty/detske/medved_3.jpg" },
                    { name: "Medvídek ", secondary: "", image: "images/dorty/detske/medvidek_1.jpg" },
                    { name: "Pejsek ", secondary: "", image: "images/dorty/detske/pejsek_1.jpeg" },
                    { name: "Pikachu ", secondary: "", image: "images/dorty/detske/pikachu_3.jpg" }
                ]
            },
            "Krémové": {
                image: "images/dort-kremovy-menu-2-tiny.png",
                items: [
                    { name: "Ovocný ", secondary: "Kokos", image: "images/dorty/ovocne/dort_ovocny_kokos.jpg" },
                    { name: "Dvoubarevný ", secondary: "50tka", image: "images/dorty/kremove/dort_dvoubarevny_50tka.jpg" },
                    { name: "Jack Daniels ", secondary: "", image: "images/dorty/kremove/dort_jack_daniels.jpg" },
                    { name: "Modrý ", secondary: "Květy", image: "images/dorty/kremove/dort_modry_kvety.jpg" },
                    { name: "Růžový ", secondary: "Zrcadlová poleva", image: "images/dorty/kremove/dort_ruzovy_zrcadlova_poleva.jpg" },
                    { name: "Dort bílý ", secondary: "", image: "images/dorty/kremove/dort_bily.jpg" },
                    { name: "Dort černý ", secondary: "", image: "images/dorty/kremove/dort_cerny.jpg" },
                    { name: "Čoko ovoce ", secondary: "", image: "images/dorty/kremove/dort_coko_ovoce.jpg" },
                    { name: "Deli ", secondary: "", image: "images/dorty/kremove/dort_deli.jpg" },
                    { name: "Duo dvojčata ", secondary: "", image: "images/dorty/kremove/dort_duo_dvojcata.jpg" },
                    { name: "Duo ", secondary: "", image: "images/dorty/kremove/dort_duo.jpg" },
                    { name: "Podkova", secondary: "", image: "images/dorty/kremove/podkova_3.jpg" },
                    { name: "Podkova ", secondary: "Čokoládová", image: "images/dorty/kremove/dort_podkova_cokoladova.jpg" },
                    { name: "Podkova ", secondary: "Křest", image: "images/dorty/kremove/dort_podkova_krest.jpg" },
                    { name: "Podkova ", secondary: "Mandle", image: "images/dorty/kremove/dort_podkova_mandle.jpg" },
                    { name: "STK ", secondary: "", image: "images/dorty/kremove/dort_stk.jpg" },
                    { name: "Vánoční ", secondary: "", image: "images/dorty/kremove/dort_vanocni.jpg" },
                    { name: "Trubičkový ", secondary: "", image: "images/dorty/kremove/dort_trubickovy.jpg" },
                    { name: "Trubičkový ", secondary: "Karamel", image: "images/dorty/kremove/dort_trubickovy_karamel.jpg" },
                    { name: "Alzák ", secondary: "", image: "images/dorty/detske/dort_alzak.jpg" },
                    { name: "Autíčko ", secondary: "", image: "images/dorty/detske/dort_auticko.jpg" },
                    { name: "Apple", secondary: "", image: "images/dorty/kremove/apple_1.jpg" },
                    { name: "Bílý a čoko", secondary: "", image: "images/dorty/kremove/white_chocolate_6.jpg" },
                    { name: "Cheesecake. ", secondary: "Borůvka.", image: "images/dorty/kremove/cheesecake_blueberry_4.jpg" },
                    { name: "Cheesecake. ", secondary: "Lesní jahoda.", image: "images/dorty/kremove/cheesecake_lesni_jahoda_2.jpg" },
                    { name: "Čoko ovoce ", secondary: "", image: "images/dorty/kremove/chocolate_fruit_2.jpg" },
                    { name: "Harlekýn", secondary: "", image: "images/dorty/kremove/harlekyn_1.jpg" },
                    { name: "Hříbkový", secondary: "", image: "images/dorty/kremove/hribkovy_1.jpg" },
                    { name: "Oreo", secondary: "", image: "images/dorty/kremove/oreo_1.jpg" },
                    { name: "Paříž", secondary: "", image: "images/dorty/kremove/paris_1.jpg" },
                    { name: "Pavlova", secondary: "", image: "images/dorty/kremove/pavlova_2.jpeg" },
                    { name: "Pistácie", secondary: "", image: "images/dorty/kremove/pistacie_1.jpg" },
                    { name: "KitKat", secondary: "", image: "images/dorty/ovocne/kitkat3.jpeg" },
                    { name: "Ovocný", secondary: "", image: "images/dorty/ovocne/ovocny_1.jpg" },
                    { name: "Čoko obdelník ", secondary: "", image: "images/dorty/kremove/dort_coko_obdelnik.jpg" }
                ]
            },
            "Klasické": {
                image: "images/classic-cakes-menu.png",
                items: [
                    { name: "Karamel", secondary: "", image: "images/dorty/klasicke/1_karamel_dort.jpg" },
                    { name: "Malina", secondary: "", image: "images/dorty/klasicke/2_dort_malina.jpg" },
                    { name: "Mandarinka", secondary: "", image: "images/dorty/klasicke/3_dort_mandarinka.jpg" },
                    { name: "Paříž", secondary: "", image: "images/dorty/klasicke/4_pariz_dort.jpg" },
                    { name: "Míša", secondary: "", image: "images/dorty/klasicke/5_misa_dort.jpg" },
                    { name: "Punčový", secondary: "", image: "images/dorty/klasicke/6_dort_puncovy.jpg" },
                    { name: "Cheesecake ", secondary: "Malina", image: "images/dorty/klasicke/7_cheesecake_malina.jpg" },
                    { name: "Cheesecake ", secondary: "Višeň", image: "images/dorty/klasicke/8_cheesecake_visen.jpg" },
                    { name: "Cheesecake ", secondary: "Borůvka", image: "images/dorty/klasicke/9_cheesecake_boruvka.jpg" },
                    { name: "Cheesecake ", secondary: "Vanilka", image: "images/dorty/klasicke/10_cheesecake_vanilka.jpg" },
                    { name: "Pavlova", secondary: "", image: "images/dorty/klasicke/11_pavlova_dort.jpeg" }
                ]
            },
            "Potažené": {
                image: "images/dort-potahovany-menu-tiny.png",
                items: [
                    { name: "Čarodějky ", secondary: "", image: "images/dorty/detske/dort_carodejky.jpg" },
                    { name: "Dort dvoupatrový Minnie ", secondary: "", image: "images/dorty/detske/dort_dvoupatrovy_minnie.jpg" },
                    { name: "Kočka ", secondary: "", image: "images/dorty/detske/dort_kocka.jpg" },
                    { name: "Nike ", secondary: "", image: "images/dorty/detske/dort_nike.jpg" },
                    { name: "Stolař ", secondary: "", image: "images/dorty/potazene/dort_stolar.jpg" },
                    { name: "Béžový", secondary: "", image: "images/dorty/potazene/bezovy_1.jpg" },
                    { name: "Bob a Bobek ", secondary: "", image: "images/dorty/detske/bob_a_bobek_1.jpg" },
                    { name: "Krteček ", secondary: "", image: "images/dorty/detske/krtecek_1.jpg" },
                    { name: "KTM ", secondary: "", image: "images/dorty/potazene/ktm_2.jpg" },
                    { name: "Medvídek ", secondary: "", image: "images/dorty/detske/medvidek_1.jpg" },
                    { name: "Pejsek ", secondary: "", image: "images/dorty/detske/pejsek_1.jpeg" },
                    { name: "Pikachu ", secondary: "", image: "images/dorty/detske/pikachu_3.jpg" },
                    { name: "Růže ", secondary: "", image: "images/dorty/potazene/ruze_2.jpg" }
                ]
            },
            "S jedlým papírem": {
                image: "images/dort-papir-menu-tiny.png",
                items: [
                    { name: "Godzila", secondary: "", image: "images/dorty/jedly_papir/dort_godzila.jpg" },
                    { name: "Minecraft 1 ", secondary: "", image: "images/dorty/detske/dort_minecraft_1.jpg" },
                    { name: "Minecraft 2", secondary: "", image: "images/dorty/jedly_papir/minecraft_1.jpeg" },
                    { name: "STK ", secondary: "", image: "images/dorty/kremove/dort_stk.jpg" },
                    { name: "Sima", secondary: "", image: "images/dorty/jedly_papir/sima_1.jpg" }
                ]
            },
            "Svatební, k přijímání, křtu": {
                image: "images/svatebni-menu.png",
                items: [
                    { name: "Dort svatební ", secondary: "Dvoupatrový", image: "images/dorty/svatebni/dort_svatebni_dvoupatrovy.jpeg" },
                    { name: "Dort svatební ", secondary: "Třípatrový červený", image: "images/dorty/svatebni/dort_svatebni_tripatrovy_cerveny.jpg" },
                    { name: "Dort svatební ", secondary: "Třípatrový žlutý", image: "images/dorty/svatebni/dort_svatebni_tripatrovy_zluty.jpg" },
                    { name: "Dort ", secondary: "Přijímání", image: "images/dorty/svatebni/dort_prijimani.jpg" },
                    { name: "Podkova ", secondary: "Křest", image: "images/dorty/kremove/dort_podkova_krest.jpg" }
                ]
            }
        },
    },
    "zakusky": {
        title: "Zákusky",
        categories: {
            "Klasické": {
                image: "images/venecky_vanilka_cukr_menu.png",
                items: [
                    { name: "Věneček - ", secondary: "Vanilka, Cukr", image: "images/zakusky/klasicke/01_venecek_vanilka_cukr.jpg" },
                    { name: "Věneček - ", secondary: "Vanilka, Fondán", image: "images/zakusky/klasicke/02_venecek_vanilka_fondan.jpg" },
                    { name: "Věneček - ", secondary: "Vanilka, Maliny", image: "images/zakusky/klasicke/03_venecek_vanilka_maliny.jpg" },
                    { name: "Věneček - ", secondary: "Pistácie", image: "images/zakusky/klasicke/04_venecek_pistacie.jpg" },
                    { name: "Věneček - ", secondary: "Čokoláda", image: "images/zakusky/klasicke/05_venecek_cokolada.jpg" },
                    { name: "Větrník ", secondary: "", image: "images/zakusky/klasicke/vetrnik_2.jpg" },
                    { name: "Ilšský - ", secondary: "Ořechový dortík", image: "images/zakusky/klasicke/07_ilssky_orechovy_dortik.jpg" },
                    { name: "Kremrole ", secondary: "", image: "images/zakusky/klasicke/08_kremrole.jpg" },
                    { name: "Laskonky ", secondary: "", image: "images/zakusky/klasicke/09_laskonky.jpg" },
                    { name: "Ovocný košíček ", secondary: "", image: "images/zakusky/klasicke/10_ovocny_kosicek.jpg" },
                    { name: "Pavlova ", secondary: "", image: "images/zakusky/klasicke/11_pavlova.jpg" },
                    { name: "Pavlova ", secondary: "mini", image: "images/zakusky/klasicke/12_pavlova_mini.jpg" },
                    { name: "Cheesecake ", secondary: "dortík", image: "images/zakusky/klasicke/13_cheesecake_dortik.jpg" },
                    { name: "Řez - ", secondary: "Harlekýn", image: "images/zakusky/klasicke/14_rez_harlekyn.jpg" },
                    { name: "Řez - ", secondary: "Paříž", image: "images/zakusky/klasicke/15_rez_pariz.jpg" },
                    { name: "Řez - ", secondary: "Mandarinka", image: "images/zakusky/klasicke/16_rez_mandarinka.jpg" },
                    { name: "Řez - ", secondary: "Pistácie", image: "images/zakusky/klasicke/17_rez_pistacie.jpg" },
                    { name: "Roláda - ", secondary: "Ovocná", image: "images/zakusky/klasicke/18_rolada_ovocna.jpg" },
                    { name: "Roláda - ", secondary: "Čokoláda", image: "images/zakusky/klasicke/19_rolada_cokolada.jpg" }
                ]
            },
            "Kelímkové": {
                image: "images/zakusky/kelimkove_dezerty_uvodni_fotka.jpg",
                items: [
                    { name: "Panna cotta - ", secondary: "Malina", image: "images/zakusky/kelimkove/01_pannacotta_malina.jpg" },
                    { name: "Panna cotta - ", secondary: "Jahoda", image: "images/zakusky/kelimkove/02_pannacotta_jahoda.jpg" },
                    { name: "Panna cotta - ", secondary: "Čokoláda", image: "images/zakusky/kelimkove/03_pannacotta_cokolada.jpg" },
                    { name: "Panna cotta - ", secondary: "Duo", image: "images/zakusky/kelimkove/04_pannacotta_duo.jpg" },
                    { name: "Panna cotta - ", secondary: "Meruňka", image: "images/zakusky/kelimkove/05_pannacotta_merunka.jpg" },
                    { name: "Tiramisu ", secondary: "", image: "images/zakusky/kelimkove/06_tiramisu.jpg" },
                    { name: "Míša ", secondary: "", image: "images/zakusky/kelimkove/07_misa.jpg" },
                    { name: "Špička ", secondary: "", image: "images/zakusky/kelimkove/08_spicka.jpg" },
                    { name: "Oreo ", secondary: "", image: "images/zakusky/kelimkove/09_oreo.jpg" },
                    { name: "Karamel ", secondary: "", image: "images/zakusky/kelimkove/10_karamel.jpg" },
                    { name: "Pistácie ", secondary: "", image: "images/zakusky/kelimkove/11_pistacie.jpg" },
                    { name: "Mecháček ", secondary: "", image: "images/zakusky/kelimkove/12_mechacek.jpg" },
                    { name: "Mango ", secondary: "", image: "images/zakusky/kelimkove/13_mango.jpg" },
                    { name: "Ovocný ", secondary: "", image: "images/zakusky/kelimkove/14_ovocny.jpg" }
                ]
            },
            "Mini dortíky": {
                image: "images/zakusky/zakusky-dortik.png",
                items: [
                    { name: "Dortík - ", secondary: "Čokoláda", image: "images/zakusky/mini_dortiky/1_dortik_cokolada.jpg" },
                    { name: "Dortík - ", secondary: "Malina", image: "images/zakusky/mini_dortiky/2_dortik_malina.jpg" },
                    { name: "Dortík - ", secondary: "Kokos", image: "images/zakusky/mini_dortiky/3_dortik_kokos.jpg" },
                    { name: "Dortík - ", secondary: "Mandle", image: "images/zakusky/mini_dortiky/4_dortik_mandle.jpg" },
                    { name: "Dortík - ", secondary: "Ovocný", image: "images/zakusky/mini_dortiky/5_dortik_ovocny.jpg" }
                ]
            },
            "Tartaletky": {
                image: "images/zakusky/zakusky-tarteletka.png",
                items: [
                    { name: "Tartaletka - ", secondary: "Čokoláda", image: "images/zakusky/tartaletky/01_tartaletka_cokolada.jpg" },
                    { name: "Tartaletka - ", secondary: "Borůvka", image: "images/zakusky/tartaletky/002_tartaletka_boruvka.jpg" },
                    { name: "Tartaletka - ", secondary: "Malina", image: "images/zakusky/tartaletky/03_tartaletka_malina.jpg" },
                    { name: "Tartaletka - ", secondary: "Ovocná", image: "images/zakusky/tartaletky/04_tartaletka_ovocna.jpg" },
                    { name: "Tartaletka - ", secondary: "Karamel", image: "images/zakusky/tartaletky/05_tartaletka_karamel.jpg" },
                    { name: "Tartaletka - ", secondary: "Pistácie", image: "images/zakusky/tartaletky/006_tartaletka_pistacie.jpg" }
                ]
            }
        },
    },
    "fr_zakusky": {
        title: "Francouzské zákusky",
        categories: {
            "default": {
                items: [
                    { name: "Srdce. ", secondary: "Jahoda a čokoláda", image: "images/fr_zakusky/srdce_jahoda_cokolada_4.jpg" },
                    { name: "Borůvkové srdce", secondary: "", image: "images/fr_zakusky/boruvkove_srdce_3.jpg" },
                    { name: "Srdce. ", secondary: "Vanilka", image: "images/fr_zakusky/vanilka_srdce_2.jpg" },
                    { name: "Citrónové dortíky", secondary: "", image: "images/fr_zakusky/citronove_dortiky_3.jpg" },
                    { name: "Čoko dortík", secondary: "", image: "images/fr_zakusky/cokodortik_1.jpg" },
                    { name: "Dárková krabička", secondary: "", image: "images/fr_zakusky/darkova_krabicka_1.jpg" },
                    { name: "Dárková krabička. ", secondary: "Srdce", image: "images/fr_zakusky/darkova_krabicka_2.jpg" },
                    { name: "Dárková krabička. ", secondary: "Mix", image: "images/fr_zakusky/darkova_krabicka_4.jpg" },
                    { name: "Eclairs. ", secondary: "Káva", image: "images/fr_zakusky/kavove_eclairs_1.jpg" },
                    { name: "Eclairs. ", secondary: "Malina", image: "images/fr_zakusky/malinove_eclairs_1.jpg" },
                    { name: "Čoko koule", secondary: "", image: "images/fr_zakusky/cokokoule_3.jpg" },
                    { name: "Kokosové koule", secondary: "", image: "images/fr_zakusky/kokosove_koule_1.jpg" },
                    { name: "Kokosové koule. ", secondary: "Růžový kokos", image: "images/fr_zakusky/kokosove_koule_ruzovy_kokos_4.jpg" },
                    { name: "Vanilka a borůvka", secondary: "", image: "images/fr_zakusky/vanilka_boruvka_1.jpg" },
                    { name: "Vanilka a jahoda", secondary: "", image: "images/fr_zakusky/vanilka_jahoda_2.jpg" }
                ],
                gallery: {
                    title: "Mix zákusků",
                    items: [
                        {
                            image: "images/fr_zakusky/gallery/mix_1.jpg"
                        },
                        {
                            image: "images/fr_zakusky/gallery/mix_2.jpg"
                        },
                        {
                            image: "images/fr_zakusky/gallery/mix_3.jpg"
                        },
                        {
                            image: "images/fr_zakusky/gallery/mix_4.jpg"
                        },
                        {
                            image: "images/fr_zakusky/gallery/mix_5.jpg"
                        },
                        {
                            image: "images/fr_zakusky/gallery/mix_6.jpg"
                        },
                        {
                            image: "images/fr_zakusky/gallery/mix_7.jpg"
                        },
                        {
                            image: "images/fr_zakusky/gallery/mix_8.jpg"
                        }
                    ]
                }
            }
        },
    },
    "chlebicky": {
        title: "Chlebíčky",
        categories: {
            "default": {
                items: [
                    { name: "Chlebíčky. ", secondary: "Herkules a debrecínka.", image: "images/chlebicky/chlebicky_herkules_debrecinka.jpg" },
                    { name: "Chlebíčky. ", secondary: "Šunka.", image: "images/chlebicky/chlebicky_sunka.jpg" }
                ]
            }
        },
    },
    "cukrovi": {
        title: "Cukroví",
        categories: {
            "default": {
                items: [
                    { name: "Vánoční. ", secondary: "Krabice mix 1 Kg.", image: "images/cukrovi/krabice_1kg.jpg" },
                    { name: "Vánoční. ", secondary: "Krabice mix 2 Kg.", image: "images/cukrovi/krabice_2kg.jpg" },
                    { name: "Svatební. ", secondary: "", image: "images/cukrovi/svatebni_cukrovi_2.jpg" },
                    { name: "Velikonoční. ", secondary: "", image: "images/cukrovi/velikonocni_cukrovi_1.jpg" }
                ]
            }
        },
    },
    "kolace": {
        title: "Koláče",
        categories: {
            "default": {
                items: [
                    { name: "Koláče. ", secondary: "", descriptions: ["Tvarohové", "Makové", "Povidlové", "Ořechové", "Jablkové"], image: "images/kolace/kolace_1.jpg" },
                    { name: "Mini koláčky. ", secondary: "", descriptions: ["Tvarohové", "Makové", "Povidlové", "Ořechové", "Jablkové"], image: "images/kolace/minikolacky_1.jpg" }
                ]
            }
        },
    }
};
